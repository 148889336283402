<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Winex Guide</div>
      </div>
    </div>
    <div class="notice-detail-wrap">
      <div class="notice-content-wrap">
        <!-- Step1 -->
        <div v-if="no === 1">
          <div class="notice-subject-wrap">
            <div class="notice-subject">Step 1 메타마스크 모바일 설치</div>
            <div class="mobile-url-copy-btn" @click="urlCopyBtn"/>
            <div class="url-copy-btn" @click="urlCopyBtn">
              <p>
                URL 복사
              </p>

            </div>
          </div>
          <div class="mobile-user-guide-content-wrap">

            <div class="mobile-user-guide-row">

              <div class="user-guide-content-wrap">
                <b>1.</b>
                <p style='text-align:left' class="pc">
                  Google Store/App Store 에서 메타마스크를 검색 후 설치 후,<br/>
                  시작하기를 누릅니다.
                </p>
                <p style='text-align:left' class="mobile">
                  Google Store/App Store 에서 메타마스크를 검색 후<br/>
                  설치 후, 시작하기를 누릅니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step1/1-1.png">
                <img src="~@/assets/images/mobile-user-guide/step1/1-2.png">
              </div>
            </div>

            <div class="mobile-user-guide-row pc" style="flex-direction: column; gap: 40px;">
              <div style="display: flex; gap: 46px;">
                <div class="user-guide-content-wrap" style="width: 776px;">
                  <b>2.</b><br/>
                  <p>비밀 복구 구문을 활용해 불러오기를 누르고,<br/>
                    동의합니다 를 누릅니다.</p>
                </div>
                <div class="user-guide-content-wrap" style="width: calc(100% - 776px - 46px);">
                  <b>3.</b><br/>
                  <p>
                    비밀 복구 구문 입력 후 비밀번호를 설정합니다.
                    비밀 복구 구문은 메타마스크 설정- 보안 및 개인정보 보호 - 비밀 복구 구문 공개 에서 보실 수 있습니다.
                  </p>
                </div>
              </div>

              <div style="display: flex; gap: 46px;">
                <div class="user-guide-image-wrap" style="width: 776px;">
                  <img src="~@/assets/images/mobile-user-guide/step1/2-1.png">
                  <img src="~@/assets/images/mobile-user-guide/step1/2-2.png">
                </div>
                <div class="user-guide-image-wrap" style="width: calc(100% - 776px - 46px);">
                  <img src="~@/assets/images/mobile-user-guide/step1/3.png">
                </div>
              </div>

            </div>

            <div class="mobile-user-guide-row mobile">

              <div class="user-guide-content-wrap">
                <b>2.</b>
                <p style='text-align:left' class="mobile">
                  비밀 복구 구문을 활용해  불러오기를 누르고,<br/>
                  동의합니다 를 누릅니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step1/2-1.png">
                <img src="~@/assets/images/mobile-user-guide/step1/2-2.png">
              </div>
            </div>
            <div class="mobile-user-guide-row mobile">

              <div class="user-guide-content-wrap">
                <b>3.</b>
                <p style='text-align:left' class="mobile">
                  비밀 복구 구문 입력 후 비밀번호를 설정합니다.
                  비밀 복구 구문은 메타마스크 설정- 보안 및 개인정보 보호 - 비밀 복구 구문 공개 에서 보실 수 있습니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step1/3.png">
              </div>
            </div>

            <div class="mobile-user-guide-row">

              <div class="user-guide-content-wrap">
                <b>4.</b>
                <p style='text-align:left' class="pc">
                  지갑의 네트워크 선택 후 Add a network - Polygon Mainnet Add - Approve - Switch to network 를 순차적으로
                  진행합니다.
                </p>
                <p style='text-align:left' class="mobile">
                  지갑의 네트워크 선택 후 Add a network - Polygon Mainnet Add - Approve - Switch to network 를 순차적으로
                  진행합니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <p class="pc">
                  <img src="~@/assets/images/mobile-user-guide/step1/4.png" style="width: 100%">
                </p>
                <p class="mobile">
                  <img src="~@/assets/images/mobile-user-guide/step1/4-1.png" style="margin-right: 10px;">
                  <img src="~@/assets/images/mobile-user-guide/step1/4-2.png" style="margin-right: 10px;">
                  <img src="~@/assets/images/mobile-user-guide/step1/4-3.png" style="margin-right: 10px;">
                  <img src="~@/assets/images/mobile-user-guide/step1/4-4.png" style="margin-right: 10px;">
                  <img src="~@/assets/images/mobile-user-guide/step1/4-5.png" style="margin-right: 10px;">
                </p>
              </div>
            </div>
            <div class="mobile-user-guide-row">

              <div class="user-guide-content-wrap">
                <b>5.</b>
                <p style='text-align:left' class="pc">
                  토큰 불러오기 - USDC 검색 - USD Coin(PoS)(USDC) 불러오기 를 누릅니다.
                </p>
                <p style='text-align:left' class="mobile">
                  토큰 불러오기 - USDC 검색 - USD Coin(PoS)(USDC) 불러오기 를 누릅니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step1/5-1.png">
                <img src="~@/assets/images/mobile-user-guide/step1/5-2.png">
                <img src="~@/assets/images/mobile-user-guide/step1/5-3.png">
              </div>
            </div>


          </div>

        </div>
        <!-- //end Step1 -->
        <!-- Step2 -->
        <div v-if="no === 2">
          <div class="notice-subject-wrap">
            <div class="notice-subject">Step 2 와넥스 모바일 이용하기</div>
            <div class="mobile-url-copy-btn" @click="urlCopyBtn"/>
            <div class="url-copy-btn" @click="urlCopyBtn">
              <p>
                URL 복사
              </p>
            </div>
          </div>
          <div class="mobile-user-guide-content-wrap">

            <div class="mobile-user-guide-row">

              <div class="user-guide-content-wrap">
                <b>1.</b>
                <p style='text-align:left' class="pc">
                  메타마스크 앱을 시작하여, 좌측 상단의 메뉴 - 브라우저 를 누릅니다.
                </p>
                <p style='text-align:left' class="mobile">
                  메타마스크 앱을 시작하여, 좌측 상단의 메뉴 -<br/>
                  브라우저 를 누릅니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step2/1-1.png">
                <img src="~@/assets/images/mobile-user-guide/step2/1-2.png">
              </div>
            </div>

            <div class="mobile-user-guide-row pc" style="flex-direction: column; gap: 33px;">
              <div style="display: flex; gap: 46px;">
                <div class="user-guide-content-wrap" style="width: calc(100% - 790px - 33px);">
                  <b>2.</b><br/>
                  <p>
                    Search or Type URL에<br/>
                    와넥스 주소인<br/>
                    https://winex.ai 를<br/>
                    입력합니다.<br/>
                  </p>
                </div>
                <div class="user-guide-content-wrap" style="width: 790px;">
                  <b>3.</b><br/>
                  <p>
                    와넥스 페이지의 우측상단의 메뉴의 Connect 를 누른 후 와넥스를 이용하시<br/>면 됩니다
                  </p>
                </div>
              </div>

              <div style="display: flex; gap: 33px;">

                <div class="user-guide-image-wrap" style="width: calc(100% - 790px - 33px);">
                  <img src="~@/assets/images/mobile-user-guide/step2/2.png">

                </div>
                <div class="user-guide-image-wrap" style="width: 790px;">
                  <img src="~@/assets/images/mobile-user-guide/step2/3-1.png">
                  <img src="~@/assets/images/mobile-user-guide/step2/3-2.png">
                </div>
              </div>

            </div>

            <div class="mobile-user-guide-row mobile">

              <div class="user-guide-content-wrap">
                <b>2.</b>
                <p style='text-align:left' class="mobile">
                  Search or Type URL에 와넥스 주소인<br/>
                  https://winex.ai 를 입력합니다.
                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step2/2.png">
              </div>
            </div>
            <div class="mobile-user-guide-row mobile">

              <div class="user-guide-content-wrap">
                <b>3.</b>
                <p style='text-align:left' class="mobile">
                  와넥스 페이지의 우측상단의 메뉴의 Connect 를 누른 후<br/>
                  와넥스를 이용하시면 됩니다

                </p>
              </div>
              <div class="user-guide-image-wrap">
                <img src="~@/assets/images/mobile-user-guide/step2/3-1.png">
                <img src="~@/assets/images/mobile-user-guide/step2/3-2.png">
              </div>
            </div>


          </div>

        </div>
        <!-- //end Step2-1 -->

        <!-- end Step4 -->
        <div class="nav"
             v-bind:style="{ 'justify-content' : (no === 1 || no === 2) ? 'space-around' : 'space-between' }">
          <!-- Prev-btn -->
          <div class="left-btn" @click="navClick(1)" v-if="no === 2">
            <div class="icon"></div>
            <div class="text">
              <div class="title">Step 1</div>
              <div class="content">메타마스크 모바일 설치</div>
            </div>
          </div>
          <!-- //end Prev-btn -->
          <!-- Next-btn -->
          <div class="right-btn" @click="navClick(2)" v-if="no === 1">
            <div class="text">
              <div class="title">Step 2</div>
              <div class="content">와넥스 모바일 이용</div>
            </div>
            <div class="icon"></div>
          </div>
          <!-- //end Next-btn -->
        </div>

      </div>

    </div>
    <div class="notice-footer">
      <ul>
        <li @click="moveList">
          <div class="prev-notice notice-footer-btn">이용가이드 목록 보기</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  name: "GuideDetail",
  data() {
    return {
      no: Number
    }
  },
  created() {
    this.no = Number(this.$route.params.no);
    // console.log(this.no);
  },
  methods: {
    navClick(no) {
      this.$router.push(
          {
            path: '/cs/guide/mobile/detail/' + no
          }
      );
    },
    urlCopyBtn() {
      var url = '';
      var textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      url = window.document.location.href;
      textarea.value = url;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert('링크가 복사 되었습니다.')
    },
    moveList() {
      this.$router.push(
          {
            path: '/cs/guide'
          }
      );
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/guide-detail.scss";
</style>
<!-- 에디터 스타일 (에디터쪽 view 스타일을 적용하면 됩니다 ~ 나중에 CMS에서 어떤 에디터를 쓸 줄 모르는 상황) -->
<style lang="scss">
.notice-content-wrap {
  img {
    width: 100%;
    margin-bottom: 8px;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.pc-notice-content {
  img {
    max-width: 100%;
  }

  p img {
    max-width: 100%;
  }
}

.mobile-notice-content {
  img {
    max-width: 100%;
  }

  p img {
    max-width: 100%;
  }
}

.line {
  border-bottom: 1px solid #ccc;
  margin: 22px 0;
}

</style>
